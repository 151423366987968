/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useMemo, useState } from "react";
import { Guild } from "@/lib/invite-manager-api/types/guild";
import { fetchGuild as _fetchGuild } from "@/lib/invite-manager-api/api";

export interface GuildState {
    guild: Guild;
}

const initialState: GuildState = {
    guild: null,
};

declare type GuildContextType = ReturnType<typeof getGuildContextValue>;
export const GuildContext = createContext<GuildContextType>(null);
const getGuildContextValue = () => {
    const [guildState, setGuildState] = useState(initialState);

    const fetchGuild = async (guildId: string) => {
        if (guildState.guild?.id === guildId) return { response: null, data: null };
        const { response, data: guild } = await _fetchGuild(guildId);

        if (response.status === 200) {
            setGuildState({ guild });
            return { response, data: guild };
        } else throw response;
    };

    return useMemo(
        () => ({
            guildState,
            fetchGuild,
        }),
        [guildState, setGuildState]
    );
};

export const GuildProvider = ({ children }) => {
    return <GuildContext.Provider value={getGuildContextValue()}>{children}</GuildContext.Provider>;
};
