/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useMemo, useState } from "react";

export interface PremiumState {
    isOpen: boolean;
}

const initialState: PremiumState = {
    isOpen: false,
};

declare type PremiumContextType = ReturnType<typeof getPremiumContextValue>;
export const PremiumContext = createContext<PremiumContextType>(null);
const getPremiumContextValue = () => {
    const [premiumState, setPremiumState] = useState(initialState);

    const setShowPremium = (value: boolean) => {
        setPremiumState({ isOpen: value });
    };

    const premiumFeature = (isPremium: boolean, callback: () => any) => {
        if (!isPremium) setShowPremium(true);
        else callback();
    };

    return useMemo(
        () => ({
            premiumState,
            setShowPremium,
            premiumFeature,
        }),
        [premiumState, setPremiumState]
    );
};

export const PremiumProvider = ({ children }) => {
    return <PremiumContext.Provider value={getPremiumContextValue()}>{children}</PremiumContext.Provider>;
};
