/* global globalThis */
import { Guild } from "./types/guild";
import { User } from "./types/user";
import { Snowflake } from "./types/types";

import { Giveaway } from "./types/giveaway";
import { RESTGetCategoryPinLeaderboard } from "./types/categories/pinLeaderboard";
import { RESTGetCategoryGiveaways } from "./types/categories/giveaways";
import { RESTGetCategoryVerification } from "./types/categories/verification";
import { RESTGetCategoryWelcomer } from "./types/categories/welcomer";
import { RESTGetCategoryMessageCounting } from "./types/categories/messageCounting";
import { RESTGetCategoryLevelling } from "./types/categories/levelling";
import { Shard } from "./types/shard";
import { sleep } from "../utils";

export const BASE_URL = process.env.BASE_API_URL;

type Response<T> = Promise<{
    data: T;
    response: globalThis.Response;
}>;

type StatusResponse = {
    message: string;
};

const json_or_text = (res: globalThis.Response): Promise<any> => {
    return res.headers.get("content-type").indexOf("application/json") !== -1 ? res.json() : res.text();
};

export const throwForStatus = <T extends StatusResponse>(resp: Awaited<Response<T>>): Awaited<Response<T>> => {
    const { response, data } = resp;
    if (400 <= response.status && response.status < 500) {
        throw data.message;
    }

    if (500 <= response.status && response.status < 600) {
        throw `Server Error (${response.status}): ${data.message}`;
    }

    return resp;
};

export async function fetcher<T>(path: string, init?: globalThis.RequestInit): Response<T> {
    let res: globalThis.Response = undefined;
    // eslint-disable-next-line no-unused-vars
    for (const _ of Array(3).keys()) {
        res = await fetch(BASE_URL + path, { ...init });
        if (res.status === 429) {
            const ratelimitReset = res.headers["X-Ratelimit-Reset"];
            const delta = (ratelimitReset && ratelimitReset * 1000 - new Date().getTime()) || 1000;
            console.log(`[fetcher] ratelimit detected, wating ${delta / 1000} seconds`);
            await sleep(delta);
            console.log(`[fetcher] done waiting for ratelimit resets `);
            continue;
        }
        break;
    }
    if (!res) throw Error("you are sending requests too fast");
    return { data: await json_or_text(res), response: res };
}

/* OAuth2 */

export const logoutUser = (): Response<StatusResponse> => {
    return fetcher("/users/@me/logout", { credentials: "include", method: "DELETE" });
};

export const fetchUser = (): Response<User> => {
    return fetcher("/users/@me", { credentials: "include" });
};

export const fetchGuild = (guildId: string): Response<Guild> => {
    return fetcher(`/managed-guilds/${guildId}`, { credentials: "include" });
};

export const fetchManagedGuilds = (): Response<Guild[]> => {
    return fetcher("/managed-guilds", { credentials: "include" });
};

export const getOAuthInviteBotOf = (guildId: string): Response<string> => {
    return fetcher(`/oauth/invite-bot/url/${guildId}`, { credentials: "include" });
};

export const exchangeOAuthCode = (code: string): Response<{ message: string }> => {
    const queryParams = new URLSearchParams({ code });
    return fetcher(`/oauth/exchange?${queryParams}`, { credentials: "include" });
};

export const fetchOAuthUrl = (): Response<string> => {
    return fetcher("/oauth/url");
};

/* --- Categories --- */

export const fetchWelcomer = (guildId: Snowflake): Response<RESTGetCategoryWelcomer> => {
    return fetcher(`/modules/${guildId}/welcomer`, { credentials: "include" });
};

export const fetchVerification = (guildId: Snowflake): Response<RESTGetCategoryVerification> => {
    return fetcher(`/modules/${guildId}/verification`, { credentials: "include" });
};

export const fetchGiveaways = (guildId: Snowflake): Response<RESTGetCategoryGiveaways> => {
    return fetcher(`/modules/${guildId}/giveaways`, { credentials: "include" });
};

export const fetchMessageCounting = (guildId: Snowflake): Response<RESTGetCategoryMessageCounting> => {
    return fetcher(`/modules/${guildId}/message_counting`, { credentials: "include" });
};

export const fetchLevelling = (guildId: Snowflake): Response<RESTGetCategoryLevelling> => {
    return fetcher(`/modules/${guildId}/levelling`, { credentials: "include" });
};

export const fetchLogging = (guildId: Snowflake): Response<any> => {
    return fetcher(`/modules/${guildId}/logging`, { credentials: "include" });
};

export const fetchPinLeaderboard = (guildId: Snowflake): Response<RESTGetCategoryPinLeaderboard> => {
    return fetcher(`/modules/${guildId}/pin_leaderboard`, { credentials: "include" });
};

export const fetchFakeInvites = (guildId: Snowflake): Response<any> => {
    return fetcher(`/modules/${guildId}/fake_invites`, { credentials: "include" });
};

/* Update methods */

export const updatePinLeaderboard = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/pin_leaderboard`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateLevelling = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/levelling`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateLogging = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/logging`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateMessageCounting = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/message_counting`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateVerification = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/verification`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateWelcomer = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/welcomer`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const updateFakeInvites = (guildId: Snowflake, payload: Record<string, any>): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/fake_invites`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

/* Other */

export const welcomerTestMessage = (guildId: Snowflake, eventType: "leave" | "welcome" | "join_dm", messageType?: "normal" | "bot" | "vanity" | "no_perms" | "unknown"): Response<StatusResponse> => {
    const payload = { event_type: eventType, message_type: messageType };
    return fetcher(`/modules/${guildId}/welcomer/test_message`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const fetchShards = (): Response<{ shards: Shard[] }> => {
    return fetcher(`/status`, { credentials: "include" });
};

export const uploadImage = (guildId: Snowflake, file: File): Response<{ url: string }> => {
    const formData = new FormData();
    formData.append("file", file);
    return fetcher(`/images/${guildId}`, { credentials: "include", body: formData, method: "POST" });
};

/* Giveaways */

export const createGiveaway = (guildId: Snowflake, payload: Giveaway): Response<StatusResponse & { giveawayId: Snowflake }> => {
    return fetcher(`/modules/${guildId}/giveaways/create`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};

export const deleteGiveaway = (guildId: Snowflake, giveawayId: Snowflake): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/giveaways/${giveawayId}/delete`, { credentials: "include", method: "DELETE" });
};

export const endGiveaway = (guildId: Snowflake, giveawayId: Snowflake): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/giveaways/${giveawayId}/end`, { credentials: "include", method: "POST" });
};

export const rerollGiveaway = (guildId: Snowflake, giveawayId: Snowflake): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/giveaways/${giveawayId}/reroll`, { credentials: "include", method: "POST" });
};

export const editGiveaway = (guildId: Snowflake, giveawayId: Snowflake, payload: Giveaway): Response<StatusResponse> => {
    return fetcher(`/modules/${guildId}/giveaways/${giveawayId}/edit`, { credentials: "include", method: "POST", body: JSON.stringify(payload) });
};
