import "../styles/globals.css";
import type { AppProps } from "next/app";

import Router from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import { Toaster, ToastBar, toast } from "react-hot-toast";

import { UserProvider } from "@/contexts/user";
import { GuildProvider } from "@/contexts/guild";
import { PremiumProvider } from "@/contexts/premium";

import MetaTags from "@/components/Sections/MetaTags";

const metaData = {
    title: "InviteCount - Build a better Discord server",
    description: "Invite Count is a powerful Discord bot which offers many features such as invite tracking, giveaways, messages tracking and more",
    url: "https://invite-count.xyz",
    image: "https://invite-count.xyz/banner.png",
    themeColor: "#8113BE",
    keywords: "invite,manager,discord,server,bot,free,invite-count,invite-manager,invite-tracker,invite-tracking",
    author: "Rafly Maulana, Alex, Runes, Martim Martins, Romain Gauvin",
    charSet: "utf-8",
    language: "English",
    icons: [
        {
            src: "/images/icon.png",
            sizes: "128x128",
            type: "image/png",
        },
    ],
};

/* Loading Bar */
NProgress.configure({
    showSpinner: false,
    template:
        '<div class="bg-purple-100 fixed z-[1031] top-0 left-0 w-full h-1" role="bar"><div class="block absolute right-0 w-[100px] h-full shadow-purple-100 shadow-[0_0_10px] opacity-100"}></div></div>',
});
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const ToasterBarComponent = ({ t }) => {
    return (
        <ToastBar toast={t}>
            {({ icon, message }) => (
                <>
                    {icon}
                    {message}
                    {t.type !== "loading" && (
                        <span
                            onClick={() => toast.dismiss(t.id)}
                            className="material-icons-outlined cursor-pointer bg-transparent transition hover:opacity-50 rounded-lg p-1.5 inline-flex items-center"
                        >
                            close
                        </span>
                    )}
                </>
            )}
        </ToastBar>
    );
};

const ToasterComponent = () => {
    const options = {
        success: { className: "!bg-blue-400 !p-5 !text-white !rounded-2xl !font-poppins" },
        loading: { className: "!bg-blue-400 !p-5 !text-white !rounded-2xl !font-poppins" },
        error: { className: "!bg-danger-default !p-5 !rounded-2xl !font-poppins", duration: null },
    };
    return (
        <Toaster position="top-center" toastOptions={options}>
            {(t) => <ToasterBarComponent t={t} />}
        </Toaster>
    );
};

const App = ({ Component, pageProps }: AppProps) => {
    return (
        <>
            {/* gtag.js script */}
            <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} strategy="afterInteractive" />
            <Script id="google-analytics" strategy="afterInteractive">
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');`}
            </Script>
            <UserProvider>
                <MetaTags metaData={metaData} />
                <GuildProvider>
                    <PremiumProvider>
                        <Component {...pageProps} />
                        <ToasterComponent />
                    </PremiumProvider>
                </GuildProvider>
            </UserProvider>
        </>
    );
};

export default App;
