import { useEffect, useRef, EffectCallback, DependencyList, useState } from "react";

export const randomChoice = <T>(arr: T[]) => arr[Math.floor(Math.random() * arr.length)];

export const sleep = (n: number) => new Promise((resolve) => setTimeout(resolve, n));

/** Function that allow to change a property of nested JSON object by a string. */
export const setProperty = (obj: Record<string, any>, path: string, value: any) => {
    const [head, ...rest] = path.split(".");

    return {
        ...obj,
        [head]: rest.length ? setProperty(obj[head], rest.join("."), value) : value,
    };
};

export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

/**
 * Allow to automatically change a nested JSON
 * state by passing the path of the attr in the JSON with a string.
 *
 * Usage
 * ------
 * ```tsx
 * const [state, setState] = useState({ attr1: "Hello", attr2: "Goodbye" })
 * <input onChange={ updateNestedState("attr1", setState) }>...</input>
 * ```
 * */
export const updateNestedState = (key: string, setState: ReturnType<typeof useState>[1]) => {
    return (value) => {
        setState((prev) => {
            const updated = setProperty({ ...prev }, key, value);
            console.log(
                `%c[StateUpdate] %c${key} %c= %c${JSON.stringify(value, null, 2)}`,
                "background: #222; color: rgb(113 103 239); font-weight: bold",
                "background: #222; color: #8113BE; font-weight: bold",
                "background: #222; color: white; font-weight: bold",
                "background: #222; color: #bada55;"
            );
            return updated;
        });
    };
};

/**
 * Returns the differences bettwen 2 nested objects.
 */
export const getDeepDiffOf = <T extends Object>(obj1: T, obj2: T): T => {
    var copy = structuredClone(obj2);
    for (let [k, v] of Object.entries(obj1)) {
        if (typeof v === "object" && v !== null) {
            // eslint-disable-next-line no-prototype-builtins
            if (!copy.hasOwnProperty(k)) {
                copy[k] = v;
            } else if (Array.isArray(copy[k]) && JSON.stringify(copy[k]) == JSON.stringify(obj1[k])) {
                delete copy?.[k];
            } else {
                getDeepDiffOf(v, copy?.[k]);
            }
        } else {
            if (Object.is(v, copy?.[k])) {
                delete copy?.[k];
            }
        }
    }
    return copy;
};

/* --- Custom hooks --- */

// eslint-disable-next-line no-unused-vars
export function useDetectOutside(callback: (event: Event) => void): ReturnType<typeof useRef<any>> {
    const ref = useRef(null);
    useEffect(() => {
        const onClickWrapper = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event);
            }
        };
        document.addEventListener("click", onClickWrapper, true);
        return () => document.removeEventListener("click", onClickWrapper, true);
    }, [ref]);
    return ref;
}

export const useEffectAfterMount = (effect: EffectCallback, deps?: DependencyList): void => {
    const mounted = useRef(true);

    useEffect(() => {
        if (!mounted.current) {
            return effect();
        }
        mounted.current = false;
    }, deps);
};

export const useRandomState = (factory: () => number = () => Math.random()): [number, () => void] => {
    const [value, setValue] = useState<number>(factory());
    return [value, () => setValue(factory())];
};
